<script setup>
  const { event, large } = defineProps({ event: Object, large: Boolean });

  // --

  const startsAt = event.prelims_starts_at || event.starts_at;
  const isLive = useDate().isLive(startsAt, event.ends_at);
  const message = isLive ? 'Live now' : useDate().formatEvent(startsAt, event.ends_at);
</script>

<!----------------------------------------------------------------------------->

<template>
  <div :class="{ isLive, large }">
    <span
      ><i v-if="isLive" /><label>{{ message }}</label></span
    >
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  div {
    span {
      @include industry-demi(px(20));

      padding: 0 px(11);

      &.islive {
        background-color: $black;
      }

      height: px(36);

      display: inline-flex;
      align-items: center;
      gap: px(11);

      white-space: nowrap;

      label {
        margin-bottom: -0.08em;
      }

      i {
        @include blink;

        width: px(12);
        height: px(12);

        margin-left: px(1);

        border-radius: 100%;
        background-color: $red;
      }
    }

    &.large {
      i {
        width: px(16);
        height: px(16);
      }
    }
  }
</style>
