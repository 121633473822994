<script setup>
  const { event } = defineProps(['event']);

  const startsAt = event.prelims_starts_at || event.starts_at;
  const hasStarted = useDate().hasStarted(startsAt);
</script>

<!----------------------------------------------------------------------------->

<template>
  <EventLive v-if="hasStarted" :event="event" class="live-top" />
  <div class="wrapper">
    <div class="content">
      <slot />
      <EventLive v-if="hasStarted" :event="event" class="live-bottom" />
      <ButtonGroup v-if="event.buttons.length" class="buttons" :buttons="event.buttons" :event="event" centerMobile />
    </div>
  </div>
  <VideoBackground
    v-if="event.header_video"
    :key="event.header_video"
    :src="event.header_video.url"
    class="background"
  />
  <SpotlightBackground v-else />
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .background {
    z-index: $layer-background;
  }

  .wrapper {
    position: relative;
    z-index: 1;
    pointer-events: none;

    .content {
      position: absolute;
      width: 100%;
      bottom: 0;
    }
  }

  .live-top {
    position: absolute;
    margin-top: px(38);

    background-color: $black;
  }

  .live-bottom {
    display: none;
    margin-top: px(18);

    background-color: $black;
  }

  .buttons {
    margin-top: px(48);
    pointer-events: auto;
  }

  @include break-medium {
    .live-top {
      margin-top: px(24);
    }
  }

  @include break-small {
    .wrapper {
      height: 100%;

      .content {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        position: static;
        // margin-top: px(-24);
      }
    }
    .live-top {
      display: none;
    }

    .live-bottom {
      display: block;
    }

    .buttons {
      // position: absolute;
      // bottom: 0;
    }
  }

  @include break-phone {
    .buttons {
      margin-top: 22vw;
    }
  }
</style>
