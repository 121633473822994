<script setup>
  const { event } = defineProps(['event']);

  const startsAt = event.prelims_starts_at || event.starts_at;
  const hasStarted = useDate().hasStarted(startsAt);
</script>

<!----------------------------------------------------------------------------->

<template>
  <HomeHeroCard :event="event">
    <div class="heading">
      <EventLogo small :event="event" />
      <h3>{{ event.fight_title }}</h3>
    </div>
    <h2>
      <FightTitleCombo>
        <template v-slot:whiteCorner>{{ event.white_corner?.last_name ?? 'TBD' }}</template>
        <template v-slot:blackCorner>{{ event.black_corner?.last_name ?? 'TBD' }}</template>
      </FightTitleCombo>
    </h2>
    <EventDateLocation v-if="!hasStarted" :event="event" class="date-location" />
  </HomeHeroCard>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .heading {
    margin-top: px(162);

    display: flex;
    align-items: center;

    margin-bottom: px(19);
    gap: px(24);

    h3 {
      @include title-s;
      margin-bottom: -0.17em;
    }
  }

  h2 {
  }

  .date-location {
    margin-top: px(16);
    margin-bottom: px(-12);
  }

  @include break-small {
    .heading {
      flex-direction: column;
      gap: px(8);

      margin-top: 0; //px(109);
      margin-bottom: px(28);

      h3 {
        text-align: center;
      }
    }

    h2 {
      align-self: stretch;
    }

    .date-location {
      margin-top: 0;
    }
  }

  @include break-phone {
    .heading {
      // margin-top: 22vw;
    }
  }
</style>
