<script setup>
  const { event } = defineProps(['event']);

  const startsAt = event.prelims_starts_at || event.starts_at;
  const { days, hours, minutes } = useDate().getCountdown(startsAt);
  const hasStarted = useDate().hasStarted(startsAt);
</script>

<!----------------------------------------------------------------------------->

<template>
  <EventLive large v-if="hasStarted" :event="event" class="live" />
  <div v-else class="clock">
    <div>
      <label>Days</label><span>{{ days }}</span>
    </div>
    <div>
      <label>Hrs</label><span>{{ hours }}</span>
    </div>
    <div>
      <label>Mins</label><span>{{ minutes }}</span>
    </div>
  </div>
</template>

<!----------------------------------------------------------------------------->

<style lang="scss" scoped>
  .clock {
    max-width: px(234);
    display: flex;
    justify-items: center;
    align-items: center;

    width: 100%;

    @include industry-demi(px(12));

    div {
      width: 100%;
      height: 100%;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:not(:last-child) {
        border-right: px(1) solid $grey-1;
      }
    }

    span {
      font-size: px(40);
      margin: px(-13) 0 px(-15);
    }

    @include break-small {
      width: px(220);

      div {
        margin-top: -0.3em;
      }
      span {
        font-size: px(32);
      }
    }
  }

  .live {
    &.isLive {
      background-color: $black;
    }

    &:deep(span) {
      width: 100%;
      height: 100%;

      justify-content: center;

      @include break-large {
        font-size: 1.5vw;
      }

      @include break-medium {
        font-size: 1.8vw;
      }

      @include break-small {
        font-size: px(20);
      }
    }
  }
</style>
